const overlay = document.querySelector('[data-js="cookie-consent"]')

// const GA_TRACKING_ID = 'UA-164075700-1'
const FB_PIXEL_ID = '1600751296808830'

function facebookPixel () {
  if (window.fbq) return
  const n = (window.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  })
  if (!window._fbq) window._fbq = n
  n.push = n
  n.loaded = !0
  n.version = '2.0'
  n.queue = []
  const t = document.createElement('script')
  t.async = !0
  t.src = 'https://connect.facebook.net/en_US/fbevents.js'
  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(t, s)

  window.fbq('init', FB_PIXEL_ID)
  window.fbq('track', 'PageView')
}

function initalizeTrackers () {
  // facebook :-(
  facebookPixel()
}

/**
 * @param {'allow'|'deny'} value
 */
function saveCookieConsent (value) {
  window.localStorage.setItem('cookieconsent_status', value)
  return window.fetch('/api/cookie_consent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ status: value })
  })
}

function askForConsent () {
  if (overlay) {
    const acceptButton = document.querySelector('[data-js="accept-cookies"]')
    const declineButton = document.querySelector('[data-js="decline-cookies"]')

    overlay.classList.add('overlay-in')

    acceptButton.addEventListener('click', function () {
      saveCookieConsent('allow')
      initalizeTrackers()
      // window['ga-disable-UA-164075700-1'] = false
      plausible('cookie_consent_accept')
      overlay.remove()
    })

    declineButton.addEventListener('click', function () {
      saveCookieConsent('deny')
      // window['ga-disable-UA-164075700-1'] = true
      overlay.remove()
    })
  }
}

/**
 * The cookie consent dance:
 * - we do it client side so we can leverage the CDN even on the html
 * - we need a server set cookie for Safari otherwise user will be prompted after 7 days of inactivity
 * - we store the state in local storage so we don't have to look it up on the server every time
 */

const cachedConstentStatus = window.localStorage.getItem('cookieconsent_status')

switch (cachedConstentStatus) {
  case 'allow':
    initalizeTrackers()
    break

  case 'deny':
    break

  default:
    window
      .fetch('/api/cookie_consent')
      .then((response) => response.json())
      .then((response) => {
        if (response.should_ask_for_consent) {
          askForConsent()
          return
        }

        window.localStorage.setItem(
          'cookieconsent_status',
          response.has_cookie_consent ? 'allow' : 'deny'
        )
        if (response.has_cookie_consent) {
          initalizeTrackers()
        }
      })
    break
}
